/* SCSS Pagina / Contacto
--------------------------------------------------- */
.contacto{

  &__content{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: $font-size;
    margin-bottom: $space-1;
  }

  &__item {
    display: flex;
    background-color: $color-grey-6;
    border-radius: $border-radius;
    padding: $font-size-smaller;
    gap: $font-size;
    text-decoration: none;
    color: $color-dark;
    &:hover {
      color: $color-accent;
    }
  }

  &__ico {
    width: 50px;
    aspect-ratio: 1;
    border-radius: $border-radius;
    background-color: $color-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: $header-3;
      color: $color-white;
    }
    &--whatsapp {
      background-color: $color-whatsapp-light;
      color: $color-dark;
    }
  }

  &__label {
    flex-direction: column;
    span {
      font-weight: $font-weight-bold;
    }
    strong {
      display: flex;
      font-weight: $font-weight-normal;
      font-size: $font-size-small;
    }
  }
  &__address {
    border: 1px dashed $color-grey-4;
    padding: $font-size;
    border-radius: $border-radius;
  }

  &__form{

  }
}