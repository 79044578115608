.cursor-pointer {
  cursor: pointer;
}

// Utilitats de colors i backgrounds
// ------------------------------------------------
$colors: (
  "primary" $color-primary,
  "primario" $color-primary,
  "primary-light" $color-primary-light,
  "primary-dark" $color-primary-dark,
  "secondary" $color-secondary,
  "secundario" $color-secondary,
  "secondary-light" $color-secondary-light,
  "secondary-dark" $color-secondary-dark,
  "accent" $color-accent,
  "accent-light" $color-accent-light,
  "accent-dark" $color-accent-dark,
  "background" $color-background,
  "white" $color-white,
  "dark" $color-dark,
  "grey-1" $color-grey-1,
  "grey-2" $color-grey-2,
  "grey-3" $color-grey-3,
  "grey-4" $color-grey-4,
  "grey-5" $color-grey-5,
  "grey-6" $color-grey-6 "gris-light" $color-grey-6
);

@each $name, $color in $colors {
  .bg-#{$name} {
    background: $color !important;
  }
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color;
  }
}

// Mides funcionals per a espais
// ------------------------------------------------
$sizes: (
  "top" "t" "0" $space-0,
  "top" "t" "1" $space-1,
  "top" "t" "2" $space-2,
  "top" "t" "3" $space-3,
  "top" "t" "4" $space-4,
  "top" "t" "5" $space-5,
  "bottom" "b" "0" $space-0,
  "bottom" "b" "1" $space-1,
  "bottom" "b" "2" $space-2,
  "bottom" "b" "3" $space-3,
  "bottom" "b" "4" $space-4,
  "bottom" "b" "5" $space-5
);

// loop top i bottom
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$d}-#{$name} {
    padding-#{$direction}: $size;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding-#{$direction}: $size / 1.5 !important;
      } @else {
        padding-#{$direction}: $size !important;
      }
    }
  }
  .mar-#{$d}-#{$name} {
    margin-#{$direction}: $size;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin-#{$direction}: ($size / 1.5) !important;
      } @else {
        margin-#{$direction}: $size !important;
      }
    }
  }
}

// loop top i bottom en y
@each $direction, $d, $name, $size in $sizes {
  .pad-#{$name} {
    padding: $size 0;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        padding: $size / 1.5 0 !important;
      } @else {
        padding: $size 0 !important;
      }
    }
  }
  .mar-#{$name} {
    margin: $size 0;
    @media screen and (max-width: 575px) {
      @if ($space-1) or ($space-2) {
        margin: $size / 1.5 0 !important;
      } @else {
        margin: $size 0 !important;
      }
    }
  }
}

/* Espacios
--------------------------------------------------------------------------- */
.padding-min {
  padding: 20px 0;
}
.padding {
  padding: 60px 0;
  @media (max-width: 767px) {
    paddding: 20px 0;
  }
}
.padding-max {
  padding: 120px 0;
  @media (max-width: 767px) {
    paddding: 50px 0;
  }
  @media (max-width: 575px) {
    paddding: 50px 0 !important;
  }
}

.padding-max-extra {
  padding: 180px 0;
}

/* Colores
--------------------------------------------------------------------------- */
.bg-primario {
  background-color: $color-primario;
}
.bg-secundario {
  background-color: $color-primario;
}
.bg-gris-light {
  background-color: $color-gris-light;
}

/* Responsive utilities
--------------------------------------------------------------------------- */

.m-no {
  @media (max-width: 575px) {
    display: none !important;
  }
}
.t-no {
  @media (min-width: 576px) and (max-width: 575px) {
    display: none;
  }
}
.t-no {
  @media (min-width: 992px) {
    display: none;
  }
}


.max-70{
  @media screen and (min-width: 992px) {
    max-width: 70%;
  }
}

.a-ratio-64{
  aspect-ratio: 6/4;
  width: 100%;
  height: auto;
  object-fit: cover;
}

sup{
  top: -.25rem !important;
}