/* Ofertas
--------------------------------------------------------------------------- */
.box-oferta {
  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
  text-align: center;
  border-radius: 10px;
  position: relative;
  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }

  &__contenido {
    padding: 1rem 1.6rem 1.6rem 1.6rem;
    @media (max-width: 575px) {
      padding: 1rem;
    }
  }

  &__etiqueta {
    position: absolute;
    top: ($font-size / 2);
    right: ($font-size / 2);
    background: yellow;
    padding: 3px 12px;
    font-weight: $font-weight-bold;
    border-radius: 50px;
    color: black;
  }

  &__contenido h4 {
    font-size: 1.4rem;
    color: var(--color-secundario);
    margin-bottom: 0rem;
    font-weight: bold;
    margin-bottom: 5px;
  }
  &__contenido p {
    font-size: 0.8rem;
  }

  img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 10px 10px 0 0 !important;
    @media (max-width: 575px) {
      height: 150px;
    }
  }

  &__datos {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__fechas {
    margin-bottom: 7px !important;
    @media (max-width: 575px) {
      margin-bottom: 0.5rem;
    }
  }
  &__dias {
    color: var(--color-primario);
    font-size: 0.75rem;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 8px 15px;
    border-radius: 20px;
    color: rgba(0, 0, 0, 0.8);
  }
  &__precio {
    margin-right: 0.3rem;
    background-color: var(--color-accent);
    color: #fff;
    display: inline-block;
    padding: 2px 15px 2px;
    border-radius: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    sup {
      font-weight: normal;
    }
  }
  &__contenido button {
    width: 100%;
  }

  &__fechas {
    font-size: 0.9rem;
    color: var(--color-primario);
  }

  &__rangos{
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  &__rangoo{}
}