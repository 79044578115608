$colors-badge: (
  "primary" $color-primary $color-dark,
  "secondary" $color-secondary $color-white,
  "accent" $color-accent $color-white,
  "white" $color-white $color-grey-3,
  "grey" $color-grey-3 $color-white
);

a.label {
  text-decoration: none;
}

.label {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: $font-size-small;
  display: inline-flex;
  margin-right: 5px;
  margin-bottom: 5px;
  @each $name, $color-badge, $color-badge-text in $colors-badge {
    &--#{$name} {
      background-color: $color-badge;
      color: $color-badge-text;
    }
  }
  &--rounded {
    border-radius: 50px;
  }
}
