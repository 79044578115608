/* SCSS Pagina / Inicio
--------------------------------------------------- */
.box-icon {
  text-align: center;
  @media (max-width: 767px) {
    margin-bottom: 3rem;
    text-align: inherit;
  }
  @media (max-width: 575px) {
    display: flex;
    margin-bottom: 0rem;
  }
  &__icon {
    @media (max-width: 575px) {
      margin-right: 1rem;
    }
  }
  &__icon img {
    height: 80px;
    margin-bottom: 1rem;
    @media (max-width: 575px) {
      height: 50px;
    }
  }
  &__content h3 {
    font-size: 1.2rem;
    font-weight: bold;
    @media (max-width: 575px) {
      margin-bottom: 3px;
    }
  }
  &__content p {
    font-size: 0.7rem;
  }
}

.reviews {
}

.home-porque{
  a{
    color: $color-secondary;
    text-decoration: underline;
    &:hover{
      color: $color-secondary;
    }
  }
}
