.galeria {
  display: grid;
  column-gap: 3rem;
  margin-bottom: 6rem;

  &__fotos {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 2px;
    @media (max-width: 767px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  &__fotos__item {
    margin-bottom: 2px;
  }
  &__fotos__item img {
    width: 100%;
    height: auto;
  }
  &__titulo {
    font-weight: bold;
    color: $color-primario;
    font-size: 1.5rem;
  }
  &__subtitulo {
    color: $color-secundario;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    @media (max-width: 767px) {
      letter-spacing: 0px;
      margin-bottom: 0;
    }
  }
  &__descripcion {
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }
  &__descripcion p {
    @media (max-width: 767px) {
      font-size: 0.85rem;
    }
  }
  &__ahora {
    margin-bottom: 1rem;
  }
  &__fotos--mini {
    grid-template-columns: repeat(10, 1fr);
  }
  &__columnas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
  }
}

.galeria-entorno {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3px;
}

.galeria--izq {
  grid-template-columns: 2fr 1fr;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}
.galeria--der {
  grid-template-columns: 1fr 2fr;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  .galeria__destacada {
    @media (max-width: 767px) {
      grid-row: 1 / 2;
    }
  }
}
.galeria--doble {
  grid-template-columns: 1fr;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .galeria__destacada {
    margin-bottom: 1rem;
  }
}

.page-template-page-apartamentos {
  .entry p {
    margin-bottom: 0.5rem !important;
  }
  .entry p,
  .entry strong {
    @media (max-width: 575px) {
      font-size: 0.85rem !important;
      line-height: 1.3rem !important;
    }
  }
}

.servicios {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: $space-1 $space-2;
}

.servicio {
  text-align: center;
}
.sercicio__icono {
  width: auto;
  height: 3rem;
}
.servicio__titulo {
  font-size: 1.05rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}
.servicio__subtitulo {
  font-family: Georgia, serif !important;
  font-size: 0.8rem;
  font-style: italic;
}

@media (max-width: 1024px) {
  .servicios {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
  .servicio__titulo {
    font-size: 0.9rem;
  }
  .servicio__subtitulo {
    font-size: 0.7rem;
  }
}

@media (max-width: 767px) {
  .servicios {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
  .servicio__titulo {
    font-size: 0.9rem;
  }
  .servicio__subtitulo {
    font-size: 0.7rem;
  }
  .sercicio__icono {
    width: auto;
    height: 3rem;
  }
}

@media (max-width: 767px) {
  .servicios {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }
  .servicio {
    margin-bottom: 1rem;
  }
  .servicio__titulo {
    font-size: 0.8rem;
  }
  .servicio__subtitulo {
    font-size: 0.7rem;
  }
  .sercicio__icono {
    width: auto;
    height: 3rem;
  }
}
