.hab-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-2;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: $space-1;
  }
  &__picture {
    img {
      width: 100%;
      height: auto;
    }
  }

  &__meta {
    margin-bottom: $space-1;
  }

  &__descripcion {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__title {
    font-size: $header-2;
    color: $color-secondary;
    font-weight: $font-weight-bold;
  }
}

.hab-desc {
  &__title {
  }

  &__listado {
    .accordion-button {
      //color: $color-white;
      background-color: $color-white;
      border-radius: 0.5rem !important;
      transition: $trans-3;
    }
    .accordion-button:not(.collapsed) {
      color: $color-white;
      font-weight: $font-weight-bold;
      background-color: $color-secondary;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      transition: $trans-3;
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff0066'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
      }
    }
    .accordion-button::after {
      width: 2rem;
      height: 2rem;
      background-size: 2rem;
      background-color: $color-white;
      border-radius: 16px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff0066'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
    }
    .accordion-button:focus {
      box-shadow: none;
    }
  }

  &__nomenclatura {
    margin-bottom: 1rem;
    border: none;
    box-shadow: $box-shadow;
    border-radius: 1rem !important;
  }

  &__nombre {
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 3rem;
  }

  &__img {
    width: 100%;
    height: auto;
  }
  &__descripcion {
  }
}

.hab-servicios {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-3;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: $space-2;
  }
  &__titulo {
    font-size: $header-3;
    color: $color-dark;
    margin-bottom: $space-1;
  }
}

.ilist {
  display: grid;
  gap: 0.4rem 2rem;
  grid-template-columns: repeat(2, 1fr);
  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: $font-size-small;
  }
  &__icon {
    width: 1.2rem;
    i {
      color: $color-accent;
    }
  }
  &__label {
  }
}

.hab-galeria {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2px;
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  img {
    aspect-ratio: 16/12;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.relacionados {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $space-2;
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &__item {
    text-align: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__picture {
    display: block;
    margin-bottom: $font-size;
  }
  &__titulo {
    font-size: $header-4;
  }
}
