.clip {
  content: "";
  position: absolute;
  z-index: 1;

  left: 0px;
  right: 0px;
  display: block;

  background: #fff;
  height: 80px;
  width: 100%;
  @media screen and (max-width: 767px) {
    height: 60px;
  }
  @media screen and (max-width: 575px) {
    height: 35px;
  }
  &--bottom {
    bottom: -1px;
    // -webkit-clip-path: ellipse(60% 80% at 50% 100%);
    clip-path: ellipse(60% 100% at 50% 100%);
  }
  &--top {
    top: -1px;
    transform: rotate(-180deg);
    // -webkit-clip-path: ellipse(60% 80% at 50% 100%);
    clip-path: ellipse(60% 100% at 50% 100%);
  }
  &--top-inv {
    top: -1px;
    // transform: rotate(-180deg);
    // -webkit-clip-path: ellipse(60% 80% at 50% 100%);
    clip-path: ellipse(60% 100% at 50% 100%);
  }
}

// Legacy
.contenedor__curved {
  height: 150px;
  width: 2000px;
  // height: 9.9vw;
  // width: 100vw;
  background: url("../../assets/bg/round-bottom.svg");
  position: absolute;
  bottom: 0px;
  background-size: 100% auto !important;
  background-position: center -50px;
  // background-position: center 0px;
  z-index: 5;
  @media screen and (max-width: 575px) {
    height: 70px;
    width: 1000px;
    background-position: center -30px;
    background-repeat: no-repeat;
  }
}

// .bg-foto--rounded--before::before {
//   top: 0 !important;
//   background-position: center top;
//   content: "";
//   position: absolute;
//   left: 50%;
//   width: 6000px;
//   height: 1500px;
//   transform: translateX(-50%);
//   background-color: transparent;
//   background-image: url("../../assets/bg/mask-bottom.svg");
//   background-size: 6000px 1500px;
// }


.page-template-page-tests{

  .wrapper-bg-foto-rounded{
    background: #fff;
    position: relative;
    transform: rotate(-180deg);
    clip-path: ellipse(80% 100% at 50% 100%);
  }

  .bg-foto--rounded {
    transform: rotate(-180deg);
  }
}