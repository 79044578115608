.form-checkin {
  h3 {
    font-weight: bold;
    color: $color-secundario;
    margin-bottom: 2rem;
    font-size: 1.3rem;
  }

  .form-row {
    margin-bottom: 3rem;
    background-color: rgba($color: #fff, $alpha: 1);
    border-color: 1px solid rgba(0, 0, 0, 0.2) !important;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: $box-shadow;
    @media (max-width: 767px) {
      padding: 2rem 1rem;
    }
  }

  .form-group {
    margin-bottom: 1.7rem;
  }

  .form-group > label {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-bottom: 5px;
    font-weight: bold;
    color: $color-gris-negro;
  }
  .form-group > label small {
    text-transform: initial;
    font-size: 90%;
  }

  input {
    font-size: 16px !important;
  }
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }

  div[data-class="wpcf7cf_group"] {
    background-color: rgba(0, 0, 0, 0.05) !important;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    .form-group {
      padding-left: 0;
      padding-right: 0;
    }
    .form-group:last-child {
      margin-bottom: 0;
    }
  }
  .wpcf7-not-valid-tip {
    background-color: rgb(188, 0, 0);
    color: #fff;
    font-size: 13px;
    padding: 0 4px;
    border-radius: 3px;
    margin-top: 2px;
    display: inline-block;
  }
}

.wpcf7 {
  margin: 0;
  @media screen and (max-width: 991px) {
    margin-top: 1.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: 0.85rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    option {
      color: rgba(0, 0, 0, 0.3) !important;
      &:checked {
        color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }

  .wpcf7-select,
  .wpcf7-file,
  .your-cv {
    color: rgba(0, 0, 0, 0.3) !important;
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: $color-grey-2;
  color: rgba(0, 0, 0, 0.4);
  a {
    color: rgba(0, 0, 0, 0.4);
    &:hover {
      color: $color-primary;
    }
  }

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

.bookly-form {
  //border: 1px solid $color-dark;
  background-color: $color-white;
  padding: 2rem;
  box-shadow: $box-shadow;
}

body:not(.page-template-page-contacto) {
  .grecaptcha-badge {
    display: none;
  }
}
