.widget {
  font-size: 0.9rem; 
  margin-bottom: 4rem;
  @media (max-width: 575px) {
    margin-bottom: 2rem;
    font-size: 0.8rem; 
  }
  &__title { 
    font-size: 1.2rem; 
    margin-bottom: 1.5rem;
    font-weight: bold;
    @media (max-width: 575px) {
      text-transform: uppercase;
      font-size: 0.9rem;
      margin-bottom: 0.8rem;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
  .widget ul { 
    list-style: none; 
    padding-left: 0px; 
  }
  .widget ul li a { 
    padding: 0.5rem 0; 
    display: block; 
    border-bottom: 2px dotted rgba(0,0,0,0.2); 
  }
}



.widget--footer { color: #fff; }
.widget--footer h4 { color: #fff; }
.widget--blog h4.widget__title { font-weight: bold !important; }



.widget_archive ul,
.widget_categories ul { display: grid; grid-template-columns: 1fr 1fr; grid-column-gap: 2rem; }