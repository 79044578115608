/* SCSS Menús
--------------------------------------------------- */
.menu-principal {
  &__nav {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    .menu-item {
      a {
        padding: $font-size-small;
        text-decoration: none;
        color: $color-white;
        font-weight: $font-weight-medium;
      }
    }
  }
}

.menu-contacto {
  display: flex;
  gap: $font-size-small;
  @media screen and (max-width: 767px) {
    gap: $font-size-smaller;
  }
  &__item {
    display: inline-flex;
    img {
      width: 38px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 30px;
      }
    }
  }
}

.offcanvas {
  &__header {
    padding: $font-size-big;
    display: flex;
    justify-content: space-between;
    img {
      width: 200px;
      height: auto;
    }
  }

  &__contacto {
    img {
      height: 20px;
      width: auto;
      margin-right: 0.5rem;
      filter: brightness(200);
    }
    .boton {
      @media screen and (max-width: 575px) {
        margin-bottom: 1rem;
        display: block;
      }
    }
  }
}

.m-overlay {
  &__menu {
    margin-bottom: $space-1;
    ul {
      list-style: none;
      padding-left: 0px;
    }
    li {
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      border-bottom: 1px dashed $color-grey-4;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      a {
        text-decoration: none;
      }
      &:last-child {
      }
    }
  }
  &__nav {
  }
}
