/* SCSS Body
--------------------------------------------------- */

body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-gris;
  font-weight: $font-weight;
  font-family: $typo-primary;
}

a {
  color: $color-primary;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
a:hover {
  color: $color-primary;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}

.container-flex {
  margin-left: 5% !important;
  margin-right: 5% !important;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 767px) {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  @media (min-width: 1400px) {
    max-width: 1350px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.container--max {
  max-width: 1600px;
}

.covid {
  background-color: rgb(255, 252, 160);
  font-size: 14px;
  padding: 5px 1rem;
  color: rgb(91, 89, 38);
  text-align: center;
  @media (max-width: 575px) {
    font-size: 12px;
  }
  a {
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
  }
}
