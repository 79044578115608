/* SCSS Headers
--------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-dark;
}

h2 {
  font-size: 1.8rem;
}

.titulo {
  color: $color-primario;
  margin-bottom: 1.5rem;
  font-weight: bold;
}
.titulo-mix {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  span {
    font-family: $typo-secundaria;
    color: $color-accent;
    font-size: 3.1rem;
  }
}
.subtitulo-min {
  font-size: 1rem;
  font-weight: bold;
}

@media (max-width: 767px) {
  .titulo-mix {
    font-size: 1.4rem;
    line-height: 2.5rem;
    span {
      font-family: $typo-secundaria;
      color: $color-accent;
      font-size: 2.9rem;
    }
  }
}

.typo-secundaria {
  font-family: var(--typo-secundaria);
  font-size: 3rem;
}
.typo-secundaria-max {
  font-family: var(--typo-secundaria);
  font-size: 4rem;
}

.texto-max {
  font-size: 1.5rem;
}

.azul {
  color: var(--color-primario);
}
.blanco {
  color: #fff !important;
}

.header-all {
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
  margin-top: 0;
  line-height: 1.4;
  color: $color-dark;
}

.header-1 {
  font-size: $header-1;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 2.1875rem;
  }
}
.header-2 {
  font-size: $header-2;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.875rem;
  }
}
.header-3 {
  font-size: $header-3;
  @extend .header-all;
  @media screen and (max-width: 767px) {
    font-size: 1.25rem;
  }
}
.header-4 {
  font-size: $header-4;
  @extend .header-all;
}
.header-5 {
  font-size: $header-5;
  @extend .header-all;
}
.header-6 {
  font-size: $header-6;
  @extend .header-all;
}

.page-body {
  p {
    font-size: $font-size;
  }
}
