/* Bg con foro
--------------------------------------------------------------------------- */
.bg-foto {
  display: flex;
  background-size: cover !important;
  position: relative !important;
  align-items: center;
  justify-content: center;
  &__contenido {
    text-align: center;
  }
  &__contenido p {
    font-size: 0.9rem;
  }
  &__overlay {
    background-color: rgba(24, 64, 122, 0.8);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }

  &__actions{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    @media screen and (max-width: 575px) {
      flex-direction: column;
      gap: $font-size;
    }
  }
}



.bg-foto--conocenos {
  height: 650px;
  background: transparent;
  background-attachment: fixed;
  background-position: center;
}
.bg-foto--entorno {
  height: 550px;
  background: transparent;
  background-attachment: fixed;
  background-position: center;
  @media (max-width: 575px) {
    height: 400px;
    background-position: center center;
    background-attachment: inherit;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.bg-foto__contenido--blanco {
  width: 500px;
  margin-left: 10%;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  margin-right: auto;
}
.bg-foto__contenido--transparente {
  width: 600px;
  color: #fff;
  position: relative;
  z-index: 3;
  align-self: center;
  justify-self: center;
}



@media (max-width: 767px) {
  .bg-foto__contenido--blanco {
    margin: auto;
  }
  .bg-foto__contenido h3 {
    font-size: 2rem;
  }
}

.wrapper-bg-foto-rounded{
  background: #fff;
  position: relative;
  transform: rotate(-180deg);
  clip-path: ellipse(80% 100% at 50% 100%);
  @media screen and (max-width: 1099px) {
    clip-path: ellipse(90% 100% at 50% 100%);
  }
  @media screen and (max-width: 991px) {
    clip-path: ellipse(100% 100% at 50% 100%);
  }
  @media screen and (max-width: 767px) {
    clip-path: ellipse(110% 100% at 50% 100%);
  }
  @media screen and (max-width: 575px) {
    clip-path: ellipse(120% 100% at 50% 100%);
  }
  @media screen and (max-width: 480px) {
    clip-path: ellipse(130% 100% at 50% 100%);
  }
  &--top{
    transform: rotate(0deg);
  }
}

.bg-foto--rounded {
  overflow: hidden;
  transform: rotate(-180deg);
}


.bg-foto--rounded-top {
  overflow: hidden;
}