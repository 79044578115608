/* Grid para noticias 
---------------------------------------------- */

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.grid-2--single {
  grid-template-columns: 5fr 2fr;
  grid-gap: 5rem;
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3rem;
  @media (min-width: 576px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr !important;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
}

.grid-3--noticias {
  grid-column-gap: 3rem;
  grid-row-gap: 6rem;
  @media (max-width: 575px) {
    grid-gap: 2rem;
  }
}

/* Noticias navegación 
------------------------------------------ */

.noticias-navigation {
  grid-column: 1 / 4;
  display: grid;
  justify-content: center;
  @media (min-width: 768px) and (max-width: 991px) {
    grid-column: 1 / 3;
  }
  @media (max-width: 575px) {
    grid-column: 1 / 2;
  }
  &__item {
    margin: 0 1rem;
  }
}

.widget-cat-home {
  margin-bottom: 2rem;
}
.widget-cat-home .widget__title {
  display: none;
}
.widget-cat-home ul {
  margin: 0 auto;
  // max-width: 1000px;
  padding-left: 0;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  @media (max-width: 575px) {
    grid-gap: inherit !important;
  }
}
.widget-cat-home ul li a {
  padding: 0.4rem 1.2rem 0.4rem;
  background-color: var(--color-background);
  border: 2px solid var(--color-gris);
  color: var(--color-gris);
  font-size: 0.8rem;
  border-radius: 50px;
  display: inline-block;
  margin-bottom: 10px;
  @media (max-width: 575px) {
    padding: 0.2rem 0.3rem 0.3rem;
    margin: 3px 5px;
  }
}

.widget-cat-home ul li.current-cat a,
.widget-cat-home ul li a:hover {
  background-color: var(--color-gris);
  color: var(--color-background);
  border: 2px solid var(--color-gris);
  transform: translateY(-2px);
}

/* Noticias thumbnail 
------------------------------------------ */

.noticias-thumb {
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  &__imagen {
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      margin-bottom: 0.2rem;
    }
    @media (max-width: 575px) {
      margin-bottom: 0.0rem;
    }
  }
  &__previa h2 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 1.2rem;
    }
    @media (max-width: 767px) {
      font-size: 1rem;
    }
    @media (max-width: 575px) {
      font-size: 1.2rem;
    }
  }
  &__cat a {
    color: var(--color-secundario);
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
  }
  &__cat {
    margin-bottom: 1rem !important;
    @media (max-width: 767px) {
      margin-bottom: 0.2rem !important;
    }
    @media (max-width: 575px) {
      margin-bottom: 0.2rem !important;
    }
  }
  &__entry {
    font-size: 0.9rem;
  }
  &__entry p {
    font-size: 0.9rem;
  }
  &__fecha {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.3);
    font-weight: bold;
    font-size: 0.8rem;
  }
}


.noticias-thumb {
  @media (max-width: 575px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  &__entry {
    @media (max-width: 575px) {
      display: none;
    }
  }
  &__cat {
    @media (max-width: 575px) {
      margin-top: 0;
      font-size: 0.7rem;
    }
  }
  &__cat a {
    @media (max-width: 575px) {
      font-size: 0.7rem;
    }
  }
  &__fecha {
    @media (max-width: 575px) {
      display: none;
    }
  }
}