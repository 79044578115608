.page-title {
  position: relative;
  // background-size: cover;
  // background-position: center center;
  padding-top: $space-5;
  padding-bottom: $space-0;
  margin-bottom: $space-1;
  color: $color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 767px) {
    padding-top: $space-4 + $font-size;
  }

  &__bg{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__overlay{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    @media screen and (max-width: 575px) {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    flex-direction: column;
    .motor-container {
      margin-bottom: -2rem;
      @media screen and (max-width: 767px) {
        margin-bottom: -$space-3;
      }
    }
  }
  &__h {
    position: relative;
    color: $color-white;
    font-size: $header-2;
    margin-bottom: $space-1 / 2;
    @media screen and (max-width: 767px) {
      font-size: $header-2;
    }
  }
  &__subtitulo {
    position: relative;
    color: $color-white;
    font-family: $typo-secundaria;
    margin-bottom: $space-1;
    letter-spacing: 1px;
    font-size: $header-2;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      line-height: 1.6;
    }
  }
  &__breadcrumbs {
    // text-transform: uppercase;
    font-size: $font-size-smaller;
    color: $color-white;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      overflow-x: auto;
      display: block;
      display: none;
    }
    a {
      color: $color-white;
    }
    @media screen and (min-width: 992px) {
      > span {
        > span {
          > a {
            margin-right: 0.2rem;
          }
          > span {
            a {
              margin-left: 0.2rem;
              margin-right: 0.2rem;
            }
          }
        }
      }
      .breadcrumb_last {
        margin-left: 0.2rem;
      }
    }
  }
}

.page-template-page-check-in,
.post-type-archive:not(.post-type-archive-ofertas) {
  .page-title {
    padding-bottom: 4rem;
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      padding-bottom: 2rem;
      margin-bottom: 0;
    }
  }
}
