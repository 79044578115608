.motor {
  background-color: $color-gris-light;
  padding: 10px;
  border-radius: 50px;
  border: 10px solid $color-primario;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  overflow: visible;
  margin-left: $font-size;
  margin-right: $font-size;
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    border-radius: 30px;
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
    border-radius: 30px;
    grid-gap: 5px;
    border-width: 4px !important;
  }
  &__input {
    display: block !important;
  }

  &__input input {
    padding: 10px 30px 7px;
    font-size: 0.85rem;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: block;
    width: 100%;
  }
  &__promo {
    @media (max-width: 575px) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
  }
  &__button {
    @media (max-width: 575px) {
      grid-column: 1 / 3;
      grid-row: 3 / 4;
    }
  }
  &__button button {
    display: block;
    width: 100%;
  }
}

.motor__input--cal input {
  padding-left: 50px;
  background-image: url("../../assets/ico/ico-cal.svg");
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 18px 18px;
}

.motor-container {
  &__list {
    padding-left: 0px !important;
    list-style: none;
    margin-top: 0.8rem;
    display: block;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
  }
  &__list li {
    display: inline-block;
    padding: 0 10px;
  }
  &__list i {
    color: $color-secundario;
  }
  &__list-item--destacado {
    color: $color-secondary;
    font-weight: $font-weight-bold;
  }
}

.hero--page .motor-container {
  @media (max-width: 480px) {
    transform: translateY(0px);
  }
}

.hero--home .motor-container {
  transform: translateY(380px);
  @media (max-width: 575px) {
    transform: translateY(150px);
  }
}

.motorBottom{
  padding: $space-2 0;
  background-color: $color-background-motor;
  .motor-container__list{
    margin-bottom: 0;
  }
  .motor-container__list-item:not(.motor-container__list-item--destacado){
    color: $color-primary;
  }
}
