.m-estancia {
  padding-left: 0;
  list-style: none;
  margin-bottom: 2rem;
  li {
    margin-bottom: 0.6rem;
  }
}

.h-estancia {
  background-color: $color-primario;
}

.page-title-estancia {
  padding-top: $space-5;
  padding-bottom: $space-2;
  background-color: rgba(0, 0, 0, 0.05);
  &__titulo {
    font-size: $header-3;
    font-weight: bold;
    margin-bottom: 0;
    color: $color-secundario !important;
  }
}
