/* SCSS Mixins
--------------------------------------------------- */
@mixin pad-l-r {
  padding-left: 1rem;
  padding-right: 1rem;
}

@mixin max-w-1700 {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  @include pad-l-r;
}
