$color-primary: #18407a;
$color-primary-light: lighten($color-primary, 10);
$color-primary-dark: darken($color-primary, 10);

$color-secondary: #ff0066;
$color-secondary-light: lighten($color-secondary, 10);
$color-secondary-dark: darken($color-secondary, 10);

$color-accent: #37cba9;
$color-accent-light: lighten($color-accent, 10);
$color-accent-dark: darken($color-accent, 10);

$color-grey-1: #262626;
$color-grey-2: #424142;
$color-grey-3: #7f7f7f;
$color-grey-4: #b6b6b6;
$color-grey-5: #d7d7d7;
$color-grey-6: #f5f5f5;

$color-white: #fff;
$color-dark: $color-grey-1;
$color-background: $color-white;
$color-background-motor: #ffdd00;

$color-whatsapp-dark: #128c7e;
$color-whatsapp-light: #25d366;

$color-text-body: $color-grey-2;
$color-text-muted: $color-grey-4;

$font-size-smaller: 0.75rem;
$font-size-small: 0.9rem;
$font-size: 1rem;
$font-size-big: 1.125rem;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$link: $color-accent;

$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);
$box-shadow-med: 0px 0px 15px rgba(0, 0, 0, 0.2);
$box-shadow: 0px 8px 16px rgba(30, 0, 40, 0.08) !important;
$box-shadow-large: 0px 16px 48px rgba(30, 0, 40, 0.1);
$box-shadow-hover: 0 0 20px 0 rgba(0, 0, 0, 0.05);

$border-radius-min: 0.3rem;
$border-radius: 0.625rem;
$border-radius-cta: 2.5rem;

$trans: 0.2s all ease-in-out;
$trans-3: 0.3s all ease-in-out;

$header-1: 3rem;
$header-2: 2.18rem;
$header-3: 1.5rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$display-1: 6rem;
$display-2: 4.35rem;
$display-3: 3.45rem;
$display-4: 2.18rem;

$space-0: 0px;
$space-1: 1.5rem; // 24px
$space-2: 2.5rem; // 40px
$space-3: 4rem; // 64px
$space-4: 5rem; // 80px
$space-5: 7.5rem; // 120px

$typo-primary: "Work Sans", sans-serif;
$typo-secondary: "Pacifico", cursive;

/**
 * Antiguos SCSS
 */
$color-primario: $color-primary;
$color-secundario: $color-secondary;
$color-accent: $color-accent;
$color-gris-light: $color-grey-6;
$color-gris: $color-grey-3;
$color-gris-dark: $color-grey-2;
$color-gris-negro: $color-grey-1;
$typo-principal: $typo-primary;
$typo-secundaria: $typo-secondary;
$font-weight: $font-weight-normal;

$sp-1: $space-2;
$sp-2: $space-4;
$sp-3: $space-5;
