.feat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include pad-l-r;
  @media screen and (min-width: 1400px) {
    grid-template-columns: 1.5fr 1fr;
    @include max-w-1700;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    margin-bottom: 3rem;
  }

  &--inverted {
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1fr 1.5fr;
      @include max-w-1700;
    }
    @media screen and (min-width: 992px) {
      .feat__picture {
        grid-column: 2 / 3;
      }
      .feat__content {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 0 3rem 0 0;
      }
    }
  }
  &__picture {
    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }

    position: relative;

    img {
      width: 100%;
      height: 540px;
      object-fit: cover;
      @media screen and (max-width: 1399px) {
        height: 450px;
      }
      @media screen and (max-width: 1199px) {
        height: 400px;
      }

      // @media screen and (max-width: 991px) {
      //   height: 300px;
      // }
      @media screen and (max-width: 575px) {
        height: 340px;
      }
      @media screen and (max-width: 480px) {
        height: 260px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 3rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0.2rem 0;
    }
  }

  &__pre {
    font-size: $font-size-small;
    text-transform: uppercase;
    color: $color-secondary;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }

  &__title {
    font-size: $header-2;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1;
    color: $color-primary;
    a {
      color: $color-primary;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 991px) {
      //font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__description {
    font-size: $font-size;
    color: $color-grey-3;

    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__actions {
    margin-top: 3rem;
    @media screen and (max-width: 991px) {
      margin-top: 1.5rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }
}

.room-data {
  //margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
  &__item {
    // background: $color-grey-6;
    // border: 1px dashed $color-primary;
    // padding: 0.5rem 1.4rem;
    // border-radius: 50px;
    //display: flex;
    //font-size: $font-size-small;
    // margin-right: 5px;
    // margin-bottom: 5px;
  }

  &__icon {
    margin-right: $font-size-smaller;
  }

  &__content {
    color: $color-white;
  }
}
