.single-ofertas .box-oferta__desc,
.single-ofertas .motor-container {
  display: none;
}

.single-ofertas .header--page {
  margin-bottom: 0 !important;
  height: 260px;
  @media screen and (max-width: 575px) {
    height: 200px;
  }
  &::before {
    display: none;
  }
}

.g-oferta {
  display: grid;
  grid-template-columns: 4fr 8fr;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 575px) {
    margin-top: -3rem;
  }
  gap: 4rem;
  &__motor {
  }

  &__content {
  }
}

.masOfertas {

  &__content {
  }

  &__pretitulo {
  }

  &__titulo {
  }

  &__items {
  }
}

.ofertasPop{
  padding: 0 $font-size;
  &__items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $space-2;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }
  &__item{
    width: 100%;
    .box-oferta{
      box-shadow: $box-shadow-med;
    }
  }
}