/* SCSS Footer
--------------------------------------------------- */

.logos {
  padding: 2rem 0;
  &__content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    img {
      width: 100%;
      height: auto;
      max-width: 160px;
      max-height: 45px;
      object-fit: contain;
      @media screen and (max-width: 575px) {
        width: 70px;
        margin: 0 0.5rem 1rem 0.5rem;
      }
    }
  }
}

.footer {
  text-align: center;
  @media (max-width: 575px) {
    padding-top: 0;
    text-align: left;
  }
  p {
    font-size: 0.8rem;
  }
  a {
    color: #fff;
  }
  h5 {
    font-size: 1.2rem;
    color: #fff;
  }
}

.logo-footer {
  width: 170px;
  height: auto;
  margin-bottom: 3rem;
}